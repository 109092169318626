import request from '@/utils/request'
import {getToken} from "@/utils/auth";
// 订单列表商城订单 订单类型(order_type  1=处方订单 2 商城订单 3 客情订单 4 三方商城订单）
export function getAdminShopOrderList(params) {
  return request({
    url: 'admin/order',
    method: 'GET',
    params
  })
}

// 客情订单 admin/crm-order
export function getAdminCrmOrderList(params) {
  return request({
    url: 'admin/crm-order',
    method: 'GET',
    params
  })
}


// 取消商城订单
export function postAdminShopCancelOrder(data) {
  return request({
    url: 'admin/cancel-order',
    method: 'POST',
    data
  })
}

// 商品详情页 admin/order-commons/{order}
export function getAdminOrderCommons(params) {
  return request({
    url: `admin/order-commons/${params.id}`,
    method: 'GET',
  })
}

// 订单备注
export function getAdminRemark(params) {
  return request({
    url: `admin/remark/${params.order}`,
    method: 'GET',
    params
  })
}

// 修改地址
export function putAdminOrderCommons(data) {
  return request({
    url: `admin/order-commons/${data.order}/contact`,
    method: 'PUT',
    data
  })
}

//导出表格 admin/order-commons-export  1=处方订单 2 商城订单 3 客情订单 4 三方商城订单
export function getAdminOrderCommonsExport(params) {
  return request({
    responseType: 'blob',
    url: 'admin/order/export',
    method: 'GET',
    params
  })
}

export function otherOrderExport(params) {
  return request({
    responseType: 'blob',
    url: 'admin/otherOrderExport',
    method: 'GET',
    params
  })
}


// 退货退款
// export function postAdminReturnOrder (data) {
//   return request({
//     url: 'admin/return-order',
//     method: 'POST',
//     data
//   })
// }

// 退货【新
export function postAdminReturnOrder(data) {
  return request({
    url: `admin/refund/${data.id}`,
    method: 'POST',
    data
  })
}


// 发货 admin/order-commons/{order}/send
export function putAdminOrderCommonsSend(data, id) {
  return request({
    url: `admin/order-commons/${id}/send`,
    method: 'PUT',
    data
  })
}

// 商品列表 查找审核通过的商品 0为未审核 1为审核通过
export function getAdminGoodList(params) {
  return request({
    url: 'admin/getGoodsList',
    method: 'GET',
    params
  })
}

// 添加订单里面的商品
export function postAdminOrderAdd(data) {
  return request({
    url: `admin/${data.order_id}/add`,
    method: 'POST',
    data
  })
}

// 修改更新订单商品 admin/{order}/update
export function postAdminOrderUpdate(data, id) {
  return request({
    url: `admin/${id}/update`,
    method: 'POST',
    data
  })
}


// 查看物流详情
export function getAdminExpressInfo(id) {
  return request({
    url: `admin/expressInfo/${id}`,
    method: 'GET',
  })
}
//删除订单
export function deleteAdminOrder(id) {
  return request({
    url: `admin/order/`+id,
    method: 'DELETE',
  })
}
//订单审核
export function putAdminOrder(id,data) {
  return request({
    url: `admin/order/examine/${id}`,
    method: 'POST',
    data,
  })
}

export function getPresOrder(params) {
  return request({
    url: `admin/getPresDrug`,
    method: 'GET',
    params,
  })
}


export function updatePresOrder(id,data) {
  return request({
    url: `admin/updateOrderPres/${id}`,
    method: 'POST',
    data,
  })
}

export function payOrder(id) {
  return request({
    url: `admin/qrcodePay/${id}`,
    method: 'POST',
  })
}
//批量更新物流信息
export function postUpdateExpressOrder(data) {
  return request({
    url: `admin/updateExpressOrder`,
    method: 'POST',
    data,
  })
}
//状态更新
export function PutStatus(id) {
  return request({
    url: `admin/order/update-to-confirm-status/${id}`,
    method: 'PUT',
  })
}
//更新订单物流反馈
export function updateExpressStatus(data) {
  return request({
    url: `admin/updateExpressStatus/${data.id}`,
    method: 'post',
    data,
  })
}
// 延时发货
export function postDelaySendOrder(data, id) {
  return request({
    url: `admin/delaySend/${id}`,
    method: 'POST',
    data,
  })
}

export function getOtherOrderList(params) {
  return request({
    url: `admin/otherOrderList`,
    method: 'get',
    params,
  })
}

export function getPharmacys( params) {
  return request({
    url: `admin/getPharmacys`,
    method: 'get',
    params,
  })
}


// 物流订单导入  
export function orderExpressImport(data) {
  return request({
      headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization' : 'Bearer '+ getToken()
      },
      url: '/admin/order_express_import',
      method: 'post',
      data: data
  })
}



