<template>
  <div class=".gg-container-1">
    <div class="search-container">
      <div class="search-container-fn-input">
        <label>姓名：</label>
        <el-input
          size="small"
          placeholder="姓名"
          v-model="searchParams.user_name"
          clearable
          @change="getList('restPage')"
        ></el-input>
      </div>
			<el-button
				size="mini"
				type="primary"
				style="padding: 6px 7px;margin-left: 5px;margin-right: 20px;
                              background-color: #2362FB; border-color: #2362FB"
				@click="getList('restPage')"
			>
				<span>查找</span>
			</el-button>
      <div class="search-container-fn-input">
        <label>手机号：</label>
        <el-input
          size="small"
          placeholder="手机号"
          v-model="searchParams.shr_mobile"
          clearable
          @change="getList('restPage')"
        ></el-input>
      </div>
			<el-button
				size="mini"
				type="primary"
				style="padding: 6px 7px;margin-left: 5px;margin-right: 20px;
                              background-color: #2362FB; border-color: #2362FB"
				@click="getList('restPage')"
			>
				<span>查找</span>
			</el-button>
      <div
        style="margin-left: auto; display: flex; justify-content: space-around"
      >
        <div class="search-container-fn-input" v-if="operateList.indexOf('delivery') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                      background-color: #2362FB; border-color: #2362FB"
            @click="handleShipments(multipleSelectionPres[0])"
            v-if="multipleSelectionPres.length == 1"
            >发货
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('remark') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                    background-color: #2362FB; border-color: #2362FB"
            @click="handleOperate(multipleSelectionPres[0], 'remark')"
            v-if="multipleSelectionPres.length == 1"
            >备注
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('export') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                    background-color: #2362FB; border-color: #2362FB"
            @click="handleExport"
            >导出订单
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('update_table') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                        background-color: #2362FB; border-color: #2362FB"
            @click="handleEditTableHead"
            >编辑表头
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('reset_query') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                        background-color: #2362FB; border-color: #2362FB"
            @click="handleResetSearch"
            >重置查找
          </el-button>
        </div>
        <!--对应第三方图标的button样式-->
      </div>
    </div>
    <!----------------表格---------------->
    <el-table
      border
      size="mini"
      v-loading="listLoading"
      :header-cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      height="725"
      @selection-change="handleSelectionChange"
      style="width: 100%;z-index:0"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column type="index" align="center" width="50">
      </el-table-column>
      <template v-for="(column, index) in tableHead">
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-if="column.field_type === 'textBtn' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a
              style="font-size: 12px; color: #2379fb"
              @click="getInfo(scope.row)"
            >
              {{ scope.row[column.column_prop] }}
            </a>
          </template>
          <template #header>
            <el-popover
              placement="bottom"
              title=""
              min-width="160"
              trigger="click"
              v-if="
                searchTableHead.filter(
                  (item) => item.name == column.column_prop
                ).length > 0
              "
            >
              <span slot="reference" class="search-header">
                <span class="search-title">{{
                  column.column_label_user_definition
                    ? column.column_label_user_definition
                    : column.column_label
                }}</span>
                <i
                  style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                  class="el-icon-search"
                  :class="{
                    isSearch: searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                    'el-icon-zoom-in': searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                  }"
                />
              </span>
              <el-input
                  v-if="'order_sn' == column.column_prop"
                  size="mini"
                  placeholder=""
                  prefix-icon="el-icon-search"
                  v-model="searchParams.order_sn"
                  clearable
                  @change="
                    handleSearch(column.column_prop, searchParams.order_sn)
                  "
                >
                </el-input>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="
            column.field_type === 'image' &&
              column.visible === true &&
              column.column_label == '医师头像'
          "
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-avatar :src="scope.row.head_pic"></el-avatar>
          </template>
        </el-table-column>
        <el-table-column
          :sortable="tableHeadSortable.includes(column.column_prop)"
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a>{{ scope.row[column.column_prop] }}</a>
          </template>
          <template #header>
            <el-popover
              placement="bottom"
              title=""
              min-width="160"
              trigger="click"
              v-if="
                searchTableHead.filter(
                  (item) => item.name == column.column_prop
                ).length > 0
              "
            >
              <span slot="reference" class="search-header">
                <span class="search-title">{{
                  column.column_label_user_definition
                    ? column.column_label_user_definition
                    : column.column_label
                }}</span>
                <i
                  style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                  class="el-icon-search"
                  :class="{
                    isSearch: searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                    'el-icon-zoom-in': searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                  }"
                />
              </span>
              <el-select
                v-if="'order_status' == column.column_prop"
                style="width: 200px"
                @change="
                  handleSearch(column.column_prop, searchParams.order_status)
                "
                v-model="searchParams.order_status"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in orderTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-date-picker
                v-if="'created_at' == column.column_prop"
                v-model="searchParams.created_at"
                :default-time="['00:00:00', '23:59:59']"
                type="daterange"
                size="mini"
                value-format="yyyy-MM-dd HH:mm:ss "
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="
                  handleSearch(column.column_prop, searchParams.created_at)
                "
              >
              </el-date-picker>
              <el-select
                v-if="'delivery_name' == column.column_prop"
                style="width: 200px"
                @change="
                  handleSearch(column.column_prop, searchParams.delivery_name)
                "
                v-model="searchParams.delivery_name"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in delivery_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
              <el-input
                v-if="'goods_name' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.goods_name"
                clearable
                @change="
                  handleSearch(column.column_prop, searchParams.goods_name)
                "
              >
              </el-input>
              <el-input
                v-if="'shr_name' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.user_name"
                clearable
                @change="
                  handleSearch(column.column_prop, searchParams.shr_name)
                "
              >
              </el-input>
              <el-input
                v-if="'shr_mobile' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.shr_mobile"
                clearable
                @change="
                  handleSearch(column.column_prop, searchParams.shr_mobile)
                "
              >
              </el-input>
            </el-popover>
            <span v-else>{{
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <!-----------------订单备注------------>
    <el-dialog
      title=""
      :visible.sync="dialogVisibleRemark"
      width="30%"
      :before-close="handleCloseRemark"
    >
      <div class="required" style="margin-bottom: 10px"></div>
      <span>订单备注</span>
      <el-input
        type="textarea"
        :rows="4"
        placeholder="订单备注"
        v-model="contentRemark"
      >
      </el-input>
      <div slot="footer" class="dialog-footer">
        <el-button class="mgr-20" size="mini" @click="handleCloseRemark"
          >取 消</el-button
        >
        <el-button
          size="mini"
          type="primary"
          @click="handleCommitRemark('ruleForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!----------------分页---------------->
    <!--<pagination :total="total" :page.sync="listQuery.page" -->
    <!--						:limit.sync="listQuery.limit"-->
    <!--						@pagination="getList"/>-->

    <Pagination
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.page_size"
      @pagination="getList"
    />
    <editTableHead
      :isActiveEditTableHead.sync="isActiveEditTableHead"
      @_getAdminFieldIndex="_getAdminFieldIndex"
      v-if="isActiveEditTableHead"
      @getList="getList"
      :table_type="table_type"
    >
    </editTableHead>
    <el-dialog :visible.sync="dialogVisible" width="30%" :show-close="false">
      <div slot="title">
        <span style="font-size:20px;border-bottom:#2362FB 3px solid"
          >确认发货</span
        >
      </div>
      <div style="padding:20px 0px;width:100%">
        <span style="font-weight: bold;">收件人信息</span>
        <div>
          <div style="width:100%;margin:10px 0;">
            <span>收货人：{{ info.shr_name }}</span>
            <span style="margin-left:10%">手机号：{{ info.shr_mobile }}</span>
          </div>
          <div>
            <span>收货地址：{{ info.region_name }}{{ info.shr_address }}</span>
          </div>
        </div>
        <div style="margin-top:20px">
          <span style="font-weight: bold;">物流信息</span>
          <div>
            <div style="width:100%;margin:20px 0;">
              <span>物流公司：</span>
              <el-select
                v-model="ruleForm.express_company"
                placeholder="请选择物流公司"
                style="width: 200px"
                size="mini"
              >
                <el-option
                  v-for="item in express_company_list"
                  :key="item.key"
                  :label="item.label"
                  :value="item.key"
                >
                </el-option>
              </el-select>
            </div>
            <div v-if="ruleForm.express_company != '患者自提'">
              <span>物流单号：</span>
              <el-input
                v-model="ruleForm.express_no"
                placeholder="请输入物流单号"
                style="width: 200px"
                size="mini"
              ></el-input>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogVisible = false;
            ruleForm = {};
          "
          size="mini"
          >取 消</el-button
        >
        <el-button type="primary" @click="_ok(info.id)" size="mini"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination"; // secondary package based on el-pagination
import { getAdminFieldIndex } from "@/newApi/drugs2.0";
import editTableHead from "@/components/editTableHead/editTableHead.vue";
import { getOrderList, orderSend } from "@/newApi/order";
import { getAdminOrderCommonsExport,getAdminRemark,getPickList} from "@/api/order";

import { Message } from "element-ui";
import { mapState } from "vuex";
export default {
  name: "memberList",
  async created() {
    await this._getAdminFieldIndex();
    this.getList();
  },
  computed:{
    ...mapState({
      table_options: state => state.user.table_options,
    })

  },
  mounted() {
    this.$store.dispatch("user/getOperates", this.$route.path).then((res) => {
      console.log(res)
      this.operateList = res;
    });
    console.log(this.operateList,222)
  },
  data() {
    return {
      table_type: "orderList",
      operateList:[],
      goodRemarkInfo: {}, //备注
      dialogVisibleRemark: false, //备注
      contentRemark: "", //备注
      isActiveEditTableHead: false,
      multipleSelectionPres: [],
      multipleSelection: [],
      activities: [],
      tableHead: [],
      searchTableHead: [
        //控制搜索
        {
          name: "goods_name",
          isSearch: false,
        },
        {
          name: "shr_name",
          isSearch: false,
        },
        {
          name: "created_at",
          isSearch: false,
        },
        {
          name: "order_type",
          isSearch: false,
        },
        {
          name: "shr_mobile",
          isSearch: false,
        },
        {
          name:'order_sn',
          isSearch:false
        },
        {
          name: "delivery_name",
          isSearch: false,
        },
      ],
      delivery_list: [], //自提点
      tableHeadSortable: [
        //控制排序
        "created_at",
        "expired_at",
        "next_visit_at",
        "end_visit_at",
        "end_order_at",
      ],
      searchParams: {
        orderTime: [],
      },
      ruleForm: {},
      orderTypeList: [
        {
          value: 1,
          label: "待确认",
        },
        {
          value: 2,
          label: "待支付",
        },
        {
          value: 3,
          label: "待发货",
        },
        {
          value: 4,
          label: "待收货",
        },
        {
          value: 5,
          label: "售后中",
        },
        {
          value: 6,
          label: "待评价",
        },
        {
          value: 7,
          label: "已完成",
        },
        {
          value: 8,
          label: "已取消",
        },
      ],
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        page_size: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      express_company_list: [
        {
          key: "患者自提",
          label: "患者自提",
        },
        {
          key: "顺丰快递",
          label: "顺丰快递",
        },
        {
          key: "中通快递",
          label: "中通快递",
        },
        {
          key: "圆通快递",
          label: "圆通快递",
        },
        {
          key: "韵达快递",
          label: "韵达快递",
        },
        {
          key: "申通快递",
          label: "申通快递",
        },
        {
          key: "百世快递",
          label: "百世快递",
        },
        {
          key: "京东快递",
          label: "京东快递",
        },
        {
          key: "邮政小包",
          label: "邮政小包",
        },
        {
          key: "邮政EMS",
          label: "邮政EMS",
        },
        {
          key: "德邦物流",
          label: "德邦物流",
        },
      ],
      tableData: [],
      fullscreenLoading: false,
      dialogVisible: false,
      info: {},
    };
  },
  components: {
    Pagination,
    editTableHead,
  },

  methods: {
    //备注
    handleCloseRemark() {
      this.contentRemark = "";
      this.dialogVisibleRemark = false;
    },
    handleCommitRemark() {
      if (this.contentRemark.length === 0) {
        this.$message({
          type: "warning",
          message: "请填写订单备注",
        });
      } else {
        var params = {
          order: this.goodRemarkInfo.id,
          content: this.contentRemark,
        };
        this._getAdminRemark(params);
      }
    },
    _getAdminRemark(data) {
      // 订单备注
      getAdminRemark(data)
        .then((response) => {
          if (response.code == 200) {
            this.contentRemark = "";
            this.dialogVisibleRemark = false;
            this.$message({
              type: "success",
              message: "备注成功",
            });
            this.getList();
          } else {
            this.$message({
              type: "error",
              message: response.msg,
            });
          }
        })
        .catch((error) => {
          this.$message({
            type: "success",
            message: error,
          });
        });
    },
    handleOperate(row){
      this.goodRemarkInfo = row
      this.dialogVisibleRemark = true;
    },
    //导出订单
    handleExport() {
      this.listLoading = true;
      let params = Object.assign({}, this.searchParams);
      getAdminOrderCommonsExport(params).then((response) => {
        console.log(response);
        const href = URL.createObjectURL(response); //创建新的URL表示指定的blob对象
        const a = document.createElement("a"); //创建a标签
        a.style.display = "none";
        a.href = href; // 指定下载链接
        a.download = "格格商城平台发货订单明细表"; //指定下载文件名
        a.click();
      });
      this.listLoading = false;
    },
    //查看详情
    getInfo(row) {
      console.log(row);
      this.$router.push({
        path: "/shopOrderMamage/orderInfo",
        query: {
          id: row.id,
          originOrder: "customer",
        },
      });
    },
    //确定
    _ok(id) {
      let info = this.ruleForm;
      if (info.express_company == "患者自提") {
        info.express_no = "";
      }
      if (!info.express_company) {
        Message.error("请选择物流公司");
        return;
      } else if (!info.express_no && info.express_company != "患者自提") {
        Message.error("请输入物流单号");
        return;
      }
      info.id = id;
      orderSend(info).then((res) => {
        if (res.code == 200) {
          Message.success("发货成功");
          this.dialogVisible = false;
          this.getList();
        } else {
          Message.error(res.msg);
        }
      });
    },
    handleSelectionChange(val) {
      console.log(val, "val");
      this.multipleSelection = [];
      this.multipleSelectionPres = [];
      val.forEach((item) => {
        this.multipleSelection.push(item.id);
        this.multipleSelectionPres.push(item);
      });
    },
    handleSearch(prop, value) {
      console.log(prop, value, "2312343");
      this.searchTableHead.forEach((item) => {
        if (item.name == prop) {
          if (value == "") {
            item.isSearch = false;
          } else {
            item.isSearch = true;
          }
        }
      });
      this.getList("restPage");
    },
    handleResetSearch() {
      this.searchParams = {
        is_wx: "",
        visit_level: "",
        buy_count: "",
      };
      this.searchTableHead.forEach((item) => {
        item.isSearch = false;
      });
      this.choiceDateCreateRecord = [];
      this.choiceDateExpire = [];
      this.choiceDateEndVisit = [];
      this.choiceDateEndOrder = [];
      this.choiceDateNextVisit = [];
      this.getList("restPage");
    },
    handleEditTableHead() {
      this.isActiveEditTableHead = true;
    },
    async _getAdminFieldIndex() {
      // try {
      //   this.listLoading = true;
      //   var params = {
      //     type: "orderList",
      //   };
      //   const res = await getAdminFieldIndex(params);
        this.tableHead = this.table_options.orderList;
      //   console.log(this.tableHead);
      // } catch (err) {
      //   //在此处理错误
      // } finally {
      //   this.listLoading = false;
      // }
    },
    getList(type) {
      if (type == "restPage") {
        this.listQuery.page = 1;
        console.log("getList触发22222");
      }
      console.log("getList触发");
      this.listLoading = true;
      this.searchParams.page = this.listQuery.page;
      this.searchParams.page_size = this.listQuery.page_size;
      this.searchParams.order_status = 3;
      // this.searchParams.origin_order_status = 3
      getOrderList(this.searchParams)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            let _data = data.data;
            this.total = _data.total;
            this.listQuery.page_size = Number(_data.per_page);
            this.tableData = _data.list;
          }
          this.fullscreenLoading = false;
          this.listLoading = false;
        })
        .catch((error) => {
          Message({
            message: error.message,
            type: "error",
          });
          this.listLoading = false;
          this.fullscreenLoading = false;
        });
    },
    reset() {
      // this.searchParams = {};
      this.listQuery.page = 1;
      this.getList();
    },
    handleShipments(row) {
      console.log(row);
      this.dialogVisible = true;
      this.ruleForm = {}
      this.info = row;
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.switch .el-switch__label {
  position: absolute;
  display: none;
  color: #fff !important;
}

/deep/.switch {
  margin-left: 8px;
}

/*打开时文字位置设置*/
/deep/.switch .el-switch__label--right {
  z-index: 1;
}

/* 调整打开时文字的显示位子 */
/deep/.switch .el-switch__label--right span {
  margin-left: -2px;
}

/*关闭时文字位置设置*/
/deep/.switch .el-switch__label--left {
  z-index: 1;
}

/* 调整关闭时文字的显示位子 */
/deep/.switch .el-switch__label--left span {
  margin-left: 22px;
}

/*显示文字*/
/deep/.switch .el-switch__label.is-active {
  display: block;
}

/* 调整按钮的宽度 */
/deep/.switch.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 60px !important;
  margin: 0;
}
.row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 30px;
}
.font-mar {
  margin-right: 20px;
}
</style>
